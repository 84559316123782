<template>
    <div>
        <Navbar />
        <Header :category="'visitors'" :bannerTitle="bannerTitle" :breadCrumb="borrowSpaceCrumb"/>
        <FacilityBody />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import FacilityBody from 'components/DefaultHome/KECC/Organizers/Facility/FacilityBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'

    export default {
        name: 'FacilityService',
        metaInfo() {
            return {
                title: this.$t("navbar.menu_1_3"),
            }
        },
        components: {
            Navbar,
            Header,
            FacilityBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: "FACUKUTUES<br>SERVICE",
                 borrowSpaceCrumb: [{
                    name : this.$t('navbar.menu_1')
                }]
            }
        }
    }
</script>

<style>
    :target:before {
        content: "";
        display: block;
        height: 80px;
    }

    hr {
        background-color: #000000;
        height: 3px;
        border: none;
    }

    .cover-image {
        margin-bottom: 50px;
        width: 100%;
        height: 400px;
    }

    .fs-btn {
        margin-left: 30px;
        margin-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }
</style>